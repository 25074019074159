import '../styles/custom.scss'
import '../styles/layout.scss'
import main_logo from '../assets/images/gt_main_logo.png'
import promo_pic from '../assets/images/premium_polo_pink.png'
import black_tshirt from '../assets/images/black_t_shirt_no_bg_h1080.png'
import company_name_image from '../assets/images/company_name_in_brand_font.png'

var slideIndex = 0;


function carousel() {
    var i;
    var x = document.getElementsByClassName("mySlides");
    for (i = 0; i < x.length; i++) {
        x[i].style.display = "none";
    }
    slideIndex++;
    if (slideIndex > x.length) {slideIndex = 1}
    x[slideIndex-1].style.display = "block";
    setTimeout(carousel, 2000); // Change image every 2 seconds
}

function load_hero_elements() {
    // document.getElementById("hero_image").src = promo_pic;
    document.getElementById("hero_image2").src = black_tshirt;
    document.getElementById("company_name_image").src = company_name_image;

    // carousel();
}

function initui() {
    document.getElementsByTagName("body")[0].classList.remove("initial-hide");
    document.getElementById("firm_main_logo").src = main_logo;
    load_hero_elements();
    document.getElementById("buy_now_button").onclick = function() {
        console.log("Buy now clicked.");
        window.location = "https://store.gtripper.in";
    }

    
    // setTimeout(() => {
    //     let lr =  document.getElementById("loader_wrapper");
    //     lr.style.fontFamily= "sea-dogregular";
    //     lr.style.display ="none";
        
    // }, 2000);
    
}


window.onload = function() {
    initui();
}